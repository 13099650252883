@import "../styles/defaults.scss";

.lc-designer-container {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $lc-white;

    &.lc-overlay-closed {
        display: none;
        z-index: initial;
    }

    smake-designer {
        width: 100%;
    }

    .lc-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        background-color: $lc-white;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
        z-index: 1;
        color: $lc-red;
        font-size: 22px;
        font-family: Augusta;
        font-weight: normal;

        .lc-logo {
            height: 60px;
        }

        .lc-button-close {
            display: block;
            cursor: pointer;
            height: 48px;
            width: 48px;
            margin-left: auto;
            margin-right: 20px;
            background-color: $lc-white;
            border-radius: 15px;
            position: relative;

            svg {
                position: absolute;
            }

            .border {
                transition: fill 0.3s;
                fill: $lc-mid-grey;
            }

            &:hover .border {
                fill: $lc-black;
            }
        }
    }

    .lc-content {
        width: 100%;
        position: relative;
        height: calc(100% - 60px);
        overflow-y: auto;

        .lc-info_hotline {
            background: black;
            color: white;
            line-height: 40px;
            position: absolute;
            padding: 0 20px;
            border-bottom-right-radius: 40px;
            border-top-right-radius: 40px;
            top: 10px;
            font-size: 13px;
            z-index: 100;

            .lc-phone_icon {
                height: 21px;
                fill: #fff;
                vertical-align: middle;
            }

            .lc-display_number {
                margin-left: 10px;
            }

            @media only screen and (max-width: $breakpoint-mobile-min-width) {
                display: none;
            }

            @media only screen and (min-width: $breakpoint-mobile-min-width) {
                pointer-events: none;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .lc-designer-container {
        .lc-content {
            #smake-designer {
                height: 100%;
                display: block;
                min-height: 709px;
            }
        }
    }
}
