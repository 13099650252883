@import "./defaults.scss";

.smake-not-available {
    display: none;
    position: absolute;

    &.show_msg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;

        .msg {
            font-size: 20px;
            font-weight: 700;
            color: $lc-black;
        }
    }
}
