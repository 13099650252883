@import "../styles/defaults.scss";

.lc-confirm-background {
    position: fixed;
    top: 0;
    z-index: 1051;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;

    &.lc-overlay-closed {
        display: none;
        z-index: initial;
    }

    .lc-confirm-modal {
        width: 600px;
        height: auto;
        max-height: calc(100vh - 140px);
        background-color: #fff;
        padding: 20px 40px;
        border: solid #d4d4d4 1px;
        margin: auto;
        display: flex;
        flex-direction: column;

        .lc-confirm-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: solid #d3d4d4 1px;
            padding: 0 0 16px 0;

            span {
                font-weight: bold;
                font-size: 16px;
                padding-right: 50px;
                width: calc(100% - 50px);
                text-transform: uppercase;
                box-sizing: border-box;
                color: #000;
            }
        }

        .lc-confirm-content {
            padding: 20px 0;

            span {
                font-size: 14px;
                padding-right: 50px;
                box-sizing: border-box;
                color: #000;
                letter-spacing: 0.3px;
            }
        }

        .lc-confirm-footer {
            border-top: solid #d3d4d4 1px;
            padding: 16px 0 0 0;

            .lc-confirm-button {
                display: flex;
                cursor: pointer;
                font-weight: 500;
                text-align: center;
                align-items: center;
                justify-content: center;
                height: 48px;
                padding: 0 30px;
                border-radius: 50px;
                border: 1px solid #d3d4d4;
                transition: border-color 300ms;
                float: left;
            }

            .lc-confirm-cancel {
                transition: background-color 300ms;
                background-color: #f0f1f1;
                border: none;
            }

            .lc-confirm-close {
                float: right;
                border: none;
                transition: background-color 300ms;
                color: #fff;
                min-width: 100px;
                background-color: #e30613;
            }
        }
    }
}
