$breakpoint-mobile-extra-small: 320px;
$breakpoint-mobile-min-width: 768px;
$breakpoint-desktop-true-min-width: 769px;
$breakpoint-tablet-min-width: 1024px;
$breakpoint-desktop-min-width: 1200px;
$breakpoint-desktop-QHD-min-width: 2500px;

$lc-black: black;
$lc-mid-grey: #D3D4D4;
$lc-white: white;
$lc-red: #e30613;
